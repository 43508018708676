import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "./index.css"
import Header from "../components/header"
import styled from "styled-components"

const title = "Terms of Use"

const Text = styled.p`
  padding-bottom: 16px;
`

const About = () => (
  <Layout>
    <Header siteTitle={title} shouldDisplayBackButton={false} />
    <div className="container3">
      <div className="container4">
        <Text>
          If you place an order for or use Amazon Emblem, you agree to the
          following terms:
        </Text>
        <ul>
          <li>
            <a href="https://www.amazon.com/gp/help/customer/display.html/?nodeId=GLSBYFE9MGKKQXXM">
              Amazon Conditions of Use
            </a>
          </li>
          <li>
            <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ'">
              Amazon Privacy Notice
            </a>
          </li>
          <li>
            <Link to="/termsOfUse">Amazon Emblem Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacyNotice">Amazon Emblem Privacy Notice</Link>
          </li>
          <li>
            <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202002080">
              Amazon Device Terms of Use
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default About
